$colorA: #ad1b02;
$colorARight: darken($colorA, 15%);
$colorALeft: darken($colorA, 5%);
$colorB: #d85604;
$colorBRight: darken($colorB, 15%);
$colorBLeft: darken($colorB, 5%);
$colorC: #f3be26;
$colorCRight: darken($colorC, 15%);
$colorCLeft: darken($colorC, 5%);
$colorD: #e669a2;
$colorDRight: darken($colorD, 15%);
$colorDLeft: darken($colorD, 5%);
$shadow: #dbe3f4;

#preloader {
  .boxes {
    --size: 22px;
    --duration: 800ms;
    height: calc(var(--size) * 2);
    width: calc(var(--size) * 3);
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    margin-top: calc(var(--size) * 1.5 * -1);
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    .box {
      width: var(--size);
      height: var(--size);
      top: 0;
      left: 0;
      position: absolute;
      transform-style: preserve-3d;
      &:nth-child(1) {
        transform: translate(100%, 0);
        animation: box1 var(--duration) linear infinite;
      }
      &:nth-child(2) {
        transform: translate(0, 100%);
        animation: box2 var(--duration) linear infinite;
        & > div {
          --background: #{$colorB};
          &:nth-child(2) {
            --background: #{$colorBRight};
          }
          &:nth-child(3) {
            --background: #{$colorBLeft};
          }
        }
      }
      &:nth-child(3) {
        transform: translate(100%, 100%);
        animation: box3 var(--duration) linear infinite;
        & > div {
            --background: #{$colorC};
            &:nth-child(2) {
              --background: #{$colorCRight};
            }
            &:nth-child(3) {
              --background: #{$colorCLeft};
            }
          }
      }
      &:nth-child(4) {
        transform: translate(200%, 0);
        animation: box4 var(--duration) linear infinite;
        & > div {
          --background: #{$colorD};
          &:nth-child(2) {
            --background: #{$colorDRight};
          }
          &:nth-child(3) {
            --background: #{$colorDLeft};
          }
        }
      }
      & > div {
        transition: all ease 0.2s;
        --background: #{$colorA};
        --top: auto;
        --right: auto;
        --bottom: auto;
        --left: auto;
        --translateZ: calc(var(--size) / 2);
        --rotateY: 0deg;
        --rotateX: 0deg;
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--background);
        top: var(--top);
        right: var(--right);
        bottom: var(--bottom);
        left: var(--left);
        transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
          translateZ(var(--translateZ));
        &:nth-child(1) {
          --top: 0;
          --left: 0;
        }
        &:nth-child(2) {
          --background: #{$colorARight};
          --right: 0;
          --rotateY: 90deg;
        }
        &:nth-child(3) {
          --background: #{$colorALeft};
          --rotateX: -90deg;
        }
        &:nth-child(4) {
          --background: #{$shadow};
          --top: 0;
          --left: 0;
          --translateZ: calc(var(--size) * 3 * -1);
        }
      }
    }
  }
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}

// html {
//     -webkit-font-smoothing: antialiased;
// }

// * {
//     box-sizing: border-box;
//     &:before,
//     &:after {
//         box-sizing: border-box;
//     }
// }

// // Center & dribbble
// body {
//     min-height: 100vh;
//     font-family: Roboto, Arial;
//     color: #ADAFB6;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #F9FBFF;
//     .dribbble {
//         position: fixed;
//         display: block;
//         right: 20px;
//         bottom: 20px;
//         img {
//             display: block;
//             height: 28px;
//         }
//     }
// }
