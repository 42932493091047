$theme-colors: (
  "primary": #e0301e,
  "secondary": #db526a,
  "success": #6ab41b,
  "info": #29b9d0,
  "warning": #ffb600,
  "danger": #f50000,
  "light": #f3f3f3,
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

html {
  scroll-behavior: smooth;
}

// body, html, #root, .App {
//   height: 100%;
//   min-height: 100%;
// }

.dx-datagrid,
.dx-datagrid-header-panel .dx-toolbar {
  background: transparent;
}
.dx-datagrid .dx-column-lines > td {
  vertical-align: middle !important;
}

.table-with-dropdown tr td:first-child {
  overflow: visible;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.dx-scheduler-appointment-popup{
  display: none;
}

#gantt {
  height: 700px;
}

// ACTIVITIES V1

#activities-v1 {
  padding-bottom: 100px;
  // overflow: visible;
  .rdt_Table .dropdown * {
    font-size: 0.95rem;
  }
  .react-datepicker-popper {
    z-index: 2031;
  }
  .tabla-actividades {
    * {
      font-size: 0.95rem;
    }
    small {
      font-size: 0.75rem;
    }
    th {
      line-height: 1rem;
    }
    th,
    td {
      white-space: nowrap;
      vertical-align: middle;
    }
    td {
      padding: 5px;
    }
    th.nombre-actividad {
      background: #f3f3f3;
      border-left: 1px solid #929292;
      border-right: 1px solid #929292;
      min-width: 400px;
    }
    td.nombre-actividad {
      border-left: 1px solid #929292;
      border-right: 1px solid #929292;
      // background-color: #fff;
      // padding: 5px 10px;
    }
    input.form-control-app,
    select.form-control-app {
      width: 100%;
      min-width: 80px;
      padding: 5px 10px;
      border: none;
      // border-radius: 5px;
      transition: all ease 0.3s;
      background: white;
    }

    input.form-control-app:focus,
    select.form-control-app:focus {
      background-color: white;
      box-shadow: 0 10px 10px -10px;
    }
    input.ancho-fecha {
      min-width: 120px;
    }
    select.select-ancho-minimo {
      min-width: 120px;
    }
    select.nivel-actividad {
      min-width: auto;
      width: 60px;
    }
    input:disabled {
      // border: 1px solid #e2e2e2 !important;
      border: 1px solid rgba(#000, 0.1);
      background: transparent !important;
    }
    .btn {
      // padding: 0.375rem 0.75rem !important;
      padding: 0.25rem 0.5rem !important;
      &:hover,
      &:active,
      &:focus {
        padding: 0.25rem 0.5rem !important;
      }
      &:active {
        background-color: map-get($theme-colors, "secondary");
      }
    }
    .form-control {
      width: calc(100% + 1.5rem);
    }
    span {
      cursor: pointer;
    }
    .mover {
      margin-right: 10px;
      color: map-get($theme-colors, "primary");
      cursor: move;
    }
    .sortable-ghost {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    }
    tr.nivel-1 {
      background: rgba(0, 0, 0, 0.05);
      border-top: 3px solid map-get($theme-colors, "light");
    }
    tr.actividad-terminada {
      background-color: rgba(map-get($theme-colors, "success"), 0.3) !important;
      //border-color: rgba(map-get($theme-colors, "success"), 50%);
      // *[name="NombreActividad"] {
      //   color: map-get($theme-colors, "success")
      // }
    }
    tr.actividad-proceso {
      background-color: rgba(map-get($theme-colors, "info"), 0.2) !important;
    }

    tr.nivel-2 {
      //border-left: 15px solid rgba(0, 0, 0, 0.05);
      background: rgba(0, 0, 0, 0.03);
      input,
      select {
        font-size: 0.9em !important;
      }
      input[name="NombreActividad"] {
        padding-left: 30px;
      }
    }

    tr.nivel-3 {
      border-left: 30px solid rgba(0, 0, 0, 0.05);
      background: rgba(0, 0, 0, 0.01);
    }
  }

  .react-datepicker__close-icon::after {
    background-color: map-get($theme-colors, "primary") !important;
    padding-top: 0 !important;
  }

  input.form-control-app.ancho-fecha[disabled]
    ~ button.react-datepicker__close-icon {
    display: none;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
}

// END ACTIVITIES V1


.table-ddl .dx-datagrid-rowsview {
  overflow: visible;
  min-height: 200px;
  tr:nth-child(odd){
    background-color: #00000005;
  }
  td {
    vertical-align: middle!important;
    padding: 1rem 7px;
  }
  tr {
    cursor: pointer;
    transition: all ease .5s;
  }
  tr td:first-child {
    overflow: visible;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .dropdown-btn-ellipsis{
    margin-right: 1rem;
    &:after {
      display: none;
    }
  }
}

.act_Atrasado {
  background-color: #E0301E !important;
}
.act_Terminada {
  background-color: #2D9E41 !important;
}
.act_Sin_Iniciar
{
  background-color: gray !important;
}
.act_En_Curso
{
  background-color: #00B1C4!important;
}

.pwc-tooltip {
  max-width: 250px;
  border-radius: 10px!important;
  @media (max-width: 991px) {
    display: none!important;
  }
  &.show{
    opacity: .95!important;
  }
  &.tip-to-front{
    z-index: 1051!important;
  }
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text.dx-state-independent {
  display: none; 
}